import React from 'react'

export const Ergo = () => {
    return (
        <section className="switchable imagebg switchable--switch image--light" data-gradient-bg="#fcf4ec,#f1b279,#fcf4ec" id="ergonomic">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 text-center"> <img alt="Image" src="img/device-5.png" /> </div>
                    <div className="col-md-5">
                        <div className="switchable__text">
                            <h2><b>Ergonomic Design</b></h2>
                            <p className="lead"> Do you need a chair that is comfortable to sit on for 8+ hours? Would you prefer a standing desk? </p>
                            <p className="lead"> We work with suppliers who know all about ergonomic design and comfort. let us know your needs a preferences and we will design an office that is built around your comfort. </p>
                            <a className="btn btn--primary1 type--uppercase" href="#" style={{marginTop: '30px'}}> <span className="btn1__text">
                                GET STARTED
                            </span> </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
