import React, { Component } from 'react'
import { Cover } from './components/Cover'
import { Intro } from './components/Intro'
import { Gallery } from './components/Gallery'
import { About } from './components/About'
import { Tabs } from './components/Tabs'
import { Pricing } from './components/Pricing'
import { Get } from './components/Get'
import { Before } from './components/Before'
import { Ergo } from './components/Ergo'
import { Corp } from './components/Corp'
import { Gift } from './components/Gift'
import { Footer } from './components/Footer'

import './Frontpage.css';
import { Nav } from './components/Nav'
import { VidCover } from './components/VidCover'
import { StickyNav } from './components/StickyNav'
import { Navbar } from './components/Navbar'


export class frontpage extends Component {

    render() {
        return (
            <div className="main-container">
                <Nav />
                {/* <StickyNav /> */}
                {/* <Navbar /> */}
                <Cover />
                <Intro />
                {/* <Gallery /> */}
                <About />
                <Before />
                <Tabs />
                <Pricing />
                <Get />
                
                <Ergo />
                <Corp />
                <Gift />
                <Footer />
            </div>
        )
    }
}

export default frontpage
