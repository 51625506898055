import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import './css/bootstrap.css'
import './css/custom.css'
import './css/flickity.css'
import './css/jquery.steps.css'
import './css/lightbox.min.css'
import './css/theme.css'
import './css/iconsmind.css'
import './css/socicon.css'
import './css/stack-interface.css'

// import './index.scss';

import Frontpage from './Frontpage'
import { Nav } from './components/Nav';
import { Footer } from './components/Footer';
import { Success } from './Success';
import { Failed } from './Failed';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Frontpage} />
        <Route path="/payment-complete" component={Success} />
        <Route path="/payment-failed" component={Failed} />
      </Switch>
    </Router>
  );
}

export default App;
