import React from 'react'
import { Product } from './Product'
import { FullProduct } from './FullProduct'
import { MiniProduct } from './MiniProduct'

export const Pricing = () => {
    return (
        <div id="packages">
            <section style={{paddingBottom: '5em'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 how-does">
                            <h2 style={{textAlign: 'left'}}><b>Our Packages</b></h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-center">
                <div className="container">
                    <div className="row">
                        <MiniProduct />
                        <Product />
                        <FullProduct />
                    </div>
                </div>
            </section>
        </div>
    )
}
