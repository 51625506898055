import React from 'react'

export const Failed = () => {
    return (
        <section className="height-100 text-center bg--dark">
            <div className="container pos-vertical-center">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="h1--large">Payment Failed!</h1>
                        <p className="lead">Please try processing your payment again.</p> <a href="/">Go back to home page</a> </div>
                </div>
            </div>
        </section>
    )
}
