import React from 'react'

export const About = () => {
    return (
        <section id="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2><b>About us</b></h2>
                        <p className="lead"> Dominic Payte is a London-based high-end interior design and build company, offering bespoke
                            refurbishment, renovation and restoration services to a high standard. With a wealth of knowledge
                            garnered over three decades, we are focused on building excellence into the future. </p>
                        <p className="lead"> Office my Home by Dominic Payte is a subsidiary of Dominic Payte focusing on the design and build
                            of affordable home offices and working spaces. </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
