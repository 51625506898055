import React from 'react'
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51H8O5kDouAxsyX9cKlkdM5dKloJLY5ZFa9KpO1U1k47qy5gA0IIjCz2kPVDOOiNUrY6vcu8d4CBu25JKwJuORhac00xBHW3uw4');


export const FullProduct = () => {

    const handleClick = async (event) => {
        console.log("Handle")
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{
            price: 'price_1HCXozDouAxsyX9cMMZlFtB2', // Replace with the ID of your price
            quantity: 1,
          }],
          mode: 'payment',
          successUrl: 'http://office.rotatestudios.com/payment-complete',
          cancelUrl: 'http://office.rotatestudios.com/payment-failed',
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
      };

    return (
        <div className="col-lg-4">
            <div className="pricing pricing-1 boxed boxed--border boxed--lg">
                <h4>Interior Design Package</h4> <span className="h1"><span className="pricing__dollar">£</span>499</span>
                <hr />
                <ul style={{textAlign: 'start'}}>
                <li><span>Personalised matching with our top Interior Designers</span></li>
                    <li><span>Initial video or phone <b>consultation</b> with your Interior Designer</span></li>
                    <li><span>Ongoing support with your Designer for the duration of the project</span></li>
                    <li><span>1 Design Concept</span></li>
                    <li><span>1 initial Floor Plan</span></li>
                    <li><span>1 Moodboard and carefully selected products and finishes</span></li>
                    <li><span>1 round of design revisions</span></li>
                    <li><span>2 x 3D photorealistic <b>renders</b> to help you visualise the design</span></li>
                    <li><span>Final floor plan and drawings for <b>installation and assembly</b></span></li>
                    <li><span>Project support throughout and up to 3 weeks post project</span></li>
                    <li><span><b>Exclusive discounts</b> on your selected products</span></li>
                </ul>
                <div className="modal-instance">
                    <a href="#" className="modal-trigger btn btn--primary-1">
                            <span className="btn__text">
                            Get Started
                        </span> 

                    </a>
                    <div className="modal-container">
                        <div className="modal-content section-modal">
                            <section className="unpad ">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10">
                                            <div className="boxed boxed--lg bg--white text-center feature">
                                                <div className="modal-close modal-close-cross"></div>
                                                <h3>Mini Package</h3>
                                                <div className="feature__body">
                                                <div className="tabs-container tabs--folder">
                                                    <ul className="tabs">
                                                        <li className="active">
                                                            <div className="tab__title">
                                                                <span className="h5">The Brief</span>
                                                            </div>
                                                            <div className="tab__content">
                                                                <p className="lead">
                                                                Initial video or phone consultation with your selected Interior Designer and ongoing support with your designer for the duration of the project
                                                                </p>
                                                                <img alt="Image" src="img/how/1how.jpg" />

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="tab__title">
                                                                <span className="h5">Before</span>
                                                            </div>
                                                            <div className="tab__content">
                                                                <p className="lead">
                                                                Design Concept including 1 round of design revisions and project support throughout the project.
                                                                </p>
                                                                <img alt="Image" src="img/how/2how.jpg" />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="tab__title">
                                                                <span className="h5">Ideas</span>
                                                            </div>
                                                            <div className="tab__content">
                                                                <p className="lead">
                                                                Moodboard and carefully selected products
                                                                </p>
                                                                <img alt="Image" src="img/how/3how.jpg" />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="tab__title">
                                                                <span className="h5">Visualisation</span>
                                                            </div>
                                                            <div className="tab__content">
                                                                <p className="lead">
                                                                Exclusive discounts on your selected products
                                                                </p>
                                                                <img alt="Image" src="img/how/4how.jpg" />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="tab__title">
                                                                <span className="h5">The Finished Design</span>
                                                            </div>
                                                            <div className="tab__content">
                                                                <p className="lead">
                                                                Exclusive discounts on your selected products
                                                                </p>
                                                                <img alt="Image" src="img/how/5how.jpg" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                    <a className="btn btn--primary1 type--uppercase" style={{marginTop: '30px'}} onClick={handleClick} > <span className="btn1__text">
                                                        PURCHASE NOW
                                                    </span> </a>
                                                    {/* <button onClick={handleClick}>
                                                        PURCHASE NOW
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
