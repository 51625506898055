import React from 'react'

export const Get = () => {
    return (
        <div id="what">
            <section className="bg--secondary" style={{paddingBottom: '5em'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 how-does">
                            <h2 style={{textAlign: 'left'}}><b>What you will get</b></h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className=" bg--secondary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="feature feature-9"> 
                                <h4>1. Your very own design by one of our top
                                    Interior Designers at a fraction of the cost</h4>
                                <img className="border--round" alt="Image" src="img/Images/1. Designer.jpg" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="feature feature-9"> 
                                <h4>2. Customised designs to suit your own
                                    personal style and budgetz</h4>
                                <img className="border--round" alt="Image" src="img/Images/2. Custom Design.jpg" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="feature feature-9"> 
                                <h4>3. Recieve discounts from all your favorite
                                    stores!</h4>
                                <img className="border--round" alt="Image" src="img/Images/3. Brands.jpg" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="feature feature-9"> 
                                <h4>4. All from the safety of your home!</h4>
                                <img className="border--round" alt="Image" src="img/Images/4. Home.jpg" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}
