import React from 'react'

export const Footer = () => {
    return (
        <div id="contact">
            <section style={{paddingBottom: '3em'}} className="bg--color">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-10 col-lg-8 how-does">
                            <h2 style={{textAlign: 'left'}}><b>How to get in touch?</b></h2>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="space--sm footer-2 bg--color hidden-sm hidden-md hidden-lg text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h6 className="type--uppercase">We’d love to hear from you!</h6>
                        </div>
                        {/* <div className="col-sm-6 col-md-6 col-xs-6">
                            <h6 className="type--uppercase">Before you go...</h6>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <h6 className="type--uppercase"></h6>
                            <ul className="list--hover">
                                <li>Call us on <a href="#">+44(0)207 305 5068</a></li>
                                <li>Email us at <a href="#">hello@officemyhome.com</a></li>
                                <li>Customer Support opening hours</li>
                                <li>Monday - Friday 9am - 5pm </li>
                                <li style={{lineHeight: '1em !important'}}>Saturday 9am - 12pm </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: "50px"}}>

                        <div className="col-sm-6">
                            <img style={{maxWidth: '100px'}} alt="background" src="img/houz.png" />
                            <ul className="list--hover">
                                <li></li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                        <img style={{maxWidth: '100px'}} alt="background" src="img/Images/local2.png" />
                            <ul className="list--hover">
                                <li></li>
                            </ul>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-12"> <span className="type--fine-print">© <span className="update-year"></span> OfficeMyHome.</span> <a className="type--fine-print" href="#">Privacy Policy</a> <a className="type--fine-print" href="#">Legal</a> </div>
                        <div className="col-sm-12">
                            <ul className="social-list list-inline list--hover">
                                <li><a href="#"><i className="socicon socicon-google icon icon--xs"></i></a></li>
                                <li><a href="#"><i className="socicon socicon-twitter icon icon--xs"></i></a></li>
                                <li><a href="#"><i className="socicon socicon-facebook icon icon--xs"></i></a></li>
                                <li><a href="#"><i className="socicon socicon-instagram icon icon--xs"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className="space--sm footer-2 bg--color hidden-xs">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-6">
                            <h6 className="type--uppercase">We’d love to hear from you!</h6>
                        </div>
                        <div className="col-sm-6 col-md-6 col-xs-6">
                            <h6 className="type--uppercase">Before you go...</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-6">
                            <h6 className="type--uppercase"></h6>
                            <ul className="list--hover">
                                <li>Call us on <a href="#">+44(0)207 305 5068</a></li>
                                <li>Email us at <a href="#">hello@officemyhome.com</a></li>
                                <li>Customer Support opening hours</li>
                                <li>Monday - Friday 9am - 5pm </li>
                                <li style={{lineHeight: '1em !important'}}>Saturday 9am - 12pm </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 col-xs-6">
                            <h6 className="type--uppercase"></h6>
                            <ul className="list--hover">
                                <li>Check us out
                                    on Houzz</li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 col-xs-6">
                            <h6 className="type--uppercase"></h6>
                            <ul className="list--hover">
                                <li>We support local
                                    businesses in the UK.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-6">
                        </div>

                        <div className="col-sm-6 col-md-3 col-xs-6">
                            <img style={{maxWidth: '100px'}} alt="background" src="img/houz.png" />
                            <ul className="list--hover">
                                <li></li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 col-xs-6">
                        <img style={{maxWidth: '100px'}} alt="background" src="img/Images/local2.png" />
                            <ul className="list--hover">
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6"> <span className="type--fine-print">© <span className="update-year"></span> OfficeMyHome.</span> <a className="type--fine-print" href="#">Privacy Policy</a> <a className="type--fine-print" href="#">Legal</a> </div>
                        <div className="col-sm-6 text-right text-left-xs">
                            <ul className="social-list list-inline list--hover">
                                <li><a href="#"><i className="socicon socicon-google icon icon--xs"></i></a></li>
                                <li><a href="#"><i className="socicon socicon-twitter icon icon--xs"></i></a></li>
                                <li><a href="#"><i className="socicon socicon-facebook icon icon--xs"></i></a></li>
                                <li><a href="#"><i className="socicon socicon-instagram icon icon--xs"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
