import React from 'react'

export const Cover = () => {
    return (
        <section className="cover imagebg height-100 text-center" data-overlay="" style={{height:'100vh'}} id="home">
            <div className="background-image-holder background--top" style={{background: "url(img/cover/bg3.jpg)", opacity: '1'}}> <img alt="background" src="img/cover/bg3.jpg" /> </div>
            <div className="container pos-vertical-center">
                <div className="row home-office">
                    <div className="col-md-8">
                        <h3 style={{textAlign: 'left'}}> Introducing the... </h3>
                        <img alt="Image" className="unmarg--bottom" src="img/headline-2-.png" />
                        <a className="btn btn--primary1 type--uppercase" href="#" style={{marginTop: '30px'}}> <span className="btn1__text">
                    GET STARTED
                </span> </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 col-lg-8">
                        <h3>“The answer to 
                            working from home.” </h3>
                        <img style={{maxWidth: '200px'}} alt="background" src="img/houz.png" />
                    </div>
                </div>
            </div>
        </section>

    )
}
