import React from 'react'

export const Before = () => {
    return (
        <div id="before">
            {/* <section style={{paddingBottom: '5em'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 how-does">
                            <h2 style={{textAlign: 'left'}}><b>Before & After</b></h2>
                        </div>
                    </div>
                </div>
            </section> */}
            <section class="text-center" style={{paddingTop: "0"}}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="tabs-container" data-content-align="left">
                                <ul class="tabs">
                                    <li class="active">
                                        <div class="tab__title"> <span class="h5"><p>Before</p></span> </div>
                                        <div class="tab__content">
                                            <img src="img/before/before1.jpg" alt="before"/>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tab__title"> <span class="h5"><p>Design</p></span> </div>
                                        <div class="tab__content">
                                        <img src="img/before/design1.jfif" alt="design"/>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="tab__title"> <span class="h5"><p>After</p></span> </div>
                                        <div class="tab__content">
                                        <img src="img/before/after1.jpg" alt="after"/>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
