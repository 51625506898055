import React from 'react'

export const Gift = () => {
    return (
        <section className="gift-rate text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="feature feature-3 boxed boxed--lg boxed--border"> <i className="icon icon-Gift-Box icon--lg"></i>
                                <h4>Gift Card</h4>
                                <p> Stack comes with integration for Mail Chimp and Campaign Monitor forms - ideal for modern marketing campaigns </p>
                                <a className="btn btn--primary1 type--uppercase" href="#" style={{marginTop: '30px'}} > <span className="btn1__text">
                                    GIVE AS A GIFT
                                </span> </a>
                    </div>        
                </div>
                        <div className="col-md-6">
                            <div className="feature feature-3 boxed boxed--lg boxed--border"> <i className="icon icon-Credit-Card3 icon--lg"></i>
                                <h4>Corporate Rates</h4>
                                <p> Including the premium Icons Mind icon kit, Stack features a highly diverse set of icons suitable for all purposes. </p>
                                <a className="btn btn--primary1 type--uppercase" href="#" style={{marginTop: '30px'}}> <span className="btn1__text">
                                    GET IN TOUCH
                                </span> </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}
