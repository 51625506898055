import React from 'react'

export const Nav = () => {
    return (
        <div className="nav-container">
            <div>
                <nav className="bar bar-toggle bar--absolute bar--transparent">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <div className="bar__module">
                                    <a href="index.html"> <img className="logo logo-dark" alt="logo" src="img/logo-dark.png" /> <img className="logo logo-light" alt="logo" src="img/logo-light.png" /> </a>
                                </div>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <div className="bar__module">
                                    <a className="menu-toggle pull-right" href="#" data-notification-link="sidebar-menu"> <i className="stack-interface stack-menu"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="notification pos-right pos-top side-menu bg--white" data-notification-link="sidebar-menu" data-animation="from-right">
                    <div className="side-menu__module pos-vertical-center text-right">
                        <ul className="menu-vertical">
                            <li className="h4"> <a href="#home" >Home</a> </li>
                            <li className="h4"> <a href="#gallery" >Gallery</a> </li>
                            <li className="h4"> <a href="#about" >About us</a> </li>
                            <li className="h4"> <a href="#how" >How does it work?</a> </li>
                            <li className="h4"> <a href="#packages" >Packages</a> </li>
                            <li className="h4"> <a href="#what" >What you will get</a> </li>
                            <li className="h4"> <a href="#before" >Before & After</a> </li>
                            <li className="h4"> <a href="#corporate" >Corporate</a> </li>
                            <li className="h4"> <a href="#contact" >Contact</a> </li>
                        </ul>
                    </div>
                    <div className="side-menu__module pos-bottom pos-absolute col-12 text-right">
                        <ul className="social-list list-inline list--hover">
                            <li><a href="#"><i className="socicon socicon-google icon icon--xs"></i></a></li>
                            <li><a href="#"><i className="socicon socicon-twitter icon icon--xs"></i></a></li>
                            <li><a href="#"><i className="socicon socicon-facebook icon icon--xs"></i></a></li>
                            <li><a href="#"><i className="socicon socicon-instagram icon icon--xs"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
