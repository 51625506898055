import React from 'react'

export const Intro = () => {
    return (
        <section>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-12">
                        <h3><b>The home is the new office.</b></h3>
                        <p className="lead"> Recent changes in our everyday life have meant that we are now spending a
                            disproportionate amount of time in our homes. We think it’s fair to say, working from
                            home is the new norm.&nbsp;</p>
                        <p className="lead"> The concept of Office My Home grew out of this new lifestyle shift. The home office is
                            now becoming one of the most important rooms/spaces in the house and needs to
                            reflect that importance. We believe that the home office is here to stay.</p>
                        <p className="lead"> We would love to design or upgrade your home office, and provide you with a beautiful
                            space to work from, which we are positive you will love!&nbsp;</p>

                        <img src="img/pngs/let-us.png" alt="" />
                        
                    </div>
                </div>
            </div>
        </section>
    )
}
