import React from 'react'

export const Corp = () => {
    return (
        // <section className="switchable imagebg switchable--switch image--light" data-gradient-bg="#fafafa" id="corporate">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-md-7 text-center"> <img alt="Image" src="img/Images/Corporate.jpg" /> </div>
        //             <div className="col-md-5">
        //                 <div className="switchable__text">
        //                     <h2><b>Corporate Office Design</b></h2>
        //                     <p className="lead"> Need help designing your office, post-Covid? </p>
        //                     <p className="lead"> Let us help you. We will design your office for you so you can have
        //                         peace of mind and ensure a happy, safe return to the office. </p>
        //                     <a className="btn btn--primary1 type--uppercase" href="#" style={{marginTop: '30px'}}> <span className="btn1__text">
        //                         GET IN TOUCH
        //                     </span> </a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>


        <section class="imageblock switchable feature-large bg--secondary">
                <div class="imageblock__content col-lg-6 col-md-4 pos-right">
                    <div class="background-image-holder"> <img alt="image" src="img/Images/Corporate.jpg" /> </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5 col-md-7">
                        <h2><b>Corporate Office Design</b></h2>
                            <p className="lead"> Need help designing your office, post-Covid? </p>
                            <p className="lead"> Let us help you. We will design your office for you so you can have
                                 peace of mind and ensure a happy, safe return to the office. </p>
                            <a className="btn btn--primary1 type--uppercase" href="#" style={{marginTop: '30px'}}> <span className="btn1__text">
                                 GET IN TOUCH
                            </span> </a>
                        </div>
                    </div>
                </div>
            </section>


    )
}
