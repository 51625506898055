import React from 'react'

export const Tabs = () => {
    return (
        <div id="how">
            <section className="bg--color" style={{paddingBottom: '0'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 how-does">
                            <h2 style={{textAlign: 'left'}}><b>How does it work?</b></h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-center bg--color" style={{paddingTop: '3em'}}>
                <div className="tabs-container" data-content-align="left">
                    <ul className="tabs tabs--spaced">
                        <li>
                            <div className="tab__title text-center"><h1>1</h1> <img className="blackIcon" alt="start" src="img/start.png" /> <img className="colorIcon" alt="start" src="img/icons/start.png" /> <span className="h5"><p>Start your project</p></span> </div>
                            <div className="tab__content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7"> <img alt="Image" src="img/how/1how.jpg" /> </div>
                                        <div className="col-md-5 col-lg-4 mid-text-fx">
                                            <div className="mid-text">
                                                <p className="lead"> Tell us about your space and what you want to achieve in a short brief including any details of what you’d like to keep or what inspires you. We’ll match you with your perfect designer for your room, style and budget. </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="active">
                            <div className="tab__title text-center"><h1>2</h1> <img className="blackIcon" alt="designer" src="img/designer.png" /> <img className="colorIcon" alt="designer" src="img/icons/designer.png" /> <span className="h5"><p>Discuss with our designers</p></span> </div>
                            <div className="tab__content">
                                <div className="container switchable switchable--switch">
                                    <div className="row">
                                        <div className="col-md-7"> <img alt="Image" src="img/how/2how.jpg" /> </div>
                                        <div className="col-md-5 col-lg-4 mid-text-fx">
                                            <div className="mid-text">
                                                <p className="lead"> Get your design recommendations and swap out furniture until you're completely happy, and there's plenty of chances to ask questions! </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="tab__title text-center"> <h1>3</h1> <img className="blackIcon" alt="package" src="img/package.png" /> <img className="colorIcon" alt="package" src="img/icons/package.png" /> <span className="h5"><p>Receive your design</p></span> </div>
                            <div className="tab__content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7"> <img alt="Image" src="img/how/3how.jpg" /> </div>
                                        <div className="col-md-5 col-lg-4 mid-text-fx">
                                            <div className="mid-text">
                                                <p className="lead"> We understand it's hard to imagine how your final room will look. Your designer will provide you with a room visualisation and layout to ensure you're delighted before finalising your furniture shopping list. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="tab__title text-center"><h1>4</h1> <img className="blackIcon" alt="discount" src="img/discount.png" /> <img className="colorIcon" alt="discount" src="img/icons/discount.png" /> <span className="h5"><p>Shopping with discounts</p></span> </div>
                            <div className="tab__content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7"> <img alt="Image" src="img/how/4how.jpg" /> </div>
                                        <div className="col-md-5 col-lg-4 mid-text-fx">
                                            <div className="mid-text">
                                                <p className="lead"> Shop all your design recommendations through RoomLab and get discounts on everything you order.</p>
                                                <hr className="short" />
                                                <p> We'll order everything for you, saving you time and hassle! </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="tab__title text-center"> <h1>5</h1> <img className="blackIcon" alt="home" src="img/home.png" /> <img className="colorIcon" alt="home" src="img/icons/home.png" /> <span className="h5"><p>Enjoy your new Home Office!</p></span> </div>
                            <div className="tab__content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7"> <img alt="Image" src="img/how/5how.jpg" /> </div>
                                        <div className="col-md-5 col-lg-4 mid-text-fx">
                                            <div className="mid-text">
                                                <h3>ENJOY!</h3>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    )
}
